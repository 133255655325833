import React from "react"
import Upload from "../../components/Upload/Upload"
import Layout from "../../components/Layout/Layout"

export default ({ location }) => {
  return (
    <Layout
      title="Upload Documents"
      process="epharmacy"
      subtitle={
        <span>
          Upload your prescription and our pharmacist will confirm the total
          price and medicine assistance <br className="is-hidden-mobile" />{" "}
          through SMS. Orders are not final until our pharmacist verifies your
          order.
        </span>
      }
      seoTitle="Upload Documents"
      display={{ footer: false, helpCenterBanner: false }}
      path={location?.pathname}
      isPrivate
      isPatient
    >
      <Upload
        flow="epharmacy"
        backRoute="/epharmacy/order"
        nextRoute="/epharmacy/checkout"
        location={location}
      />
    </Layout>
  )
}
