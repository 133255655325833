import React, { useRef, useContext, useEffect } from "react"

import Dropzone from "./Dropzone"
import CartLayout from "../Layout/CartLayout"

import EpharmacyCart from "../Epharmacy/Cart"
import UploadGuidelines from "./UploadGuidelines"
import { AppContext } from "../../context/AppContext"
import DocumentsCardRow from "../Cart/DocumentsCard/DocumentsCardRow"

const MAX_FILE_SIZE_IN_BYTES = 3145728
const MAX_FILE_COUNT = 6

const Upload = ({ backRoute, nextRoute, flow, withCart = true, location }) => {
  const fileInputRef = useRef(null)
  const { dispatch, state } = useContext(AppContext)
  const { documents } = state

  const Cart = (
    <EpharmacyCart
      open={{ documentsCard: true }}
      withCart={withCart}
      location={location}
    />
  )

  const handleFileChooser = () => {
    fileInputRef.current.click()
  }

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeleteDocument = async (index) => {
    const documentsList = [...documents]
    documentsList.splice(index, 1)
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        documents: [...documentsList],
      },
    })
  }

  return (
    <CartLayout
      isCentered
      desktop={10}
      fullhd={8}
      cartContainer={Cart}
      back={{ label: "Back", link: backRoute, className: "is-hidden-desktop" }}
      next={{
        label: "Next: Checkout",
        link: nextRoute,
        disabled: !state?.documents.length,
        className: "is-hidden-desktop",
      }}
    >
      <UploadGuidelines />
      <div className="my-2 is-hidden-desktop">
        {documents.map((file, index) => (
          <DocumentsCardRow
            key={index}
            fileName={file.name}
            oldFileName={file.oldname}
            index={index}
            handleDeleteDocument={handleDeleteDocument}
            file={file}
          />
        ))}
      </div>
      <Dropzone
        fileInputRef={fileInputRef}
        handleFileChooser={handleFileChooser}
        maxByteSize={MAX_FILE_SIZE_IN_BYTES}
        maxFileCount={MAX_FILE_COUNT}
        docType="RX"
      />
    </CartLayout>
  )
}

export default Upload
